<template>
  <v-card elevation="0">
    <div class="d-flex justify-space-between mt-4 mb-2">
      <h4 class="mb-0">{{ title }}</h4>
      <router-link
        v-if="showMoreBtn"
        :to="toList"
        class="primary--text pointer"
        >更多</router-link
      >
    </div>
    
    <v-divider></v-divider>

    <clientItem v-for="item in items" :data="item" :key="item.store_id" />
  </v-card>
</template>

<script>
export default {
  props: {
    indexType: {
      type: String,
    },
  },
  components: {
    clientItem: () =>
      import("@/modules/provider/components/clientItem/clientItem.vue"),
  },
  data: () => ({
    items: [],
    limitCount: 3,
    pager: null,
  }),
  computed: {
    params() {
      return {
        page: 1,
        filter: {
          status: this.indexType,
        },
      };
    },
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    title() {
      return this.$t(`provider.status.${this.indexType}`);
    },
    total() {
      if (!this.pager) return 0;
      return this.pager.total;
    },
    showMoreBtn() {
      return this.total > this.limitCount;
    },
    toList() {
      return {
        name: "client-list",
        query: {
          filter: {
            status: this.indexType,
          },
        },
      };
    },
  },
  mounted() {
    this.index();
  },
  methods: {
    async index() {
      this.$store.dispatch("loading/active");
      try {
        const res = await this.$api.collection.providerApi.clientIndex(
          this.providerId,
          this.params
        );
        this.items = this.limitItemsLength(res.data);
        this.pager = res.pager;
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    limitItemsLength(items) {
      return items.splice(0, this.limitCount);
    },
  },
};
</script>